import axios from 'axios'
import React, { useEffect, useState, lazy, Suspense } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

import { PageContext, SessionContext } from 'session'

const AppRouter = lazy(() => import('./AppRouter'))
const Login = lazy(() => import('pages/Login'))

const App = () => {
    const [isLoaded, setLoaded] = useState(false)
    const [user, setUser] = useState(null)
    const [error, setError] = useState(false)
    const [title, setTitle] = useState('')

    useEffect(() => {
        const loadUser = async () => {
            const response = await axios.get('/users/load')
            const { success, ...user } = response.data
            if (success) {
                setUser(user)
            }
            setLoaded(true)
        }
        loadUser()
    }, [])

    const signin = async (username, password) => {
        const response = await axios.post('/users/signin', { username, password })
        const { success, ...user } = response.data
        if (success) {
            setUser(user)
        } else {
            setError(true)
        }
    }

    const signout = async () => {
        await axios.post('/users/signout')
        setUser(null)
        setError(false)
    }

    return (
        <Suspense fallback={<div>загружаю...</div>}>
            <CssBaseline />
            {isLoaded && (
                <PageContext.Provider value={{ title, setTitle }}>
                    <SessionContext.Provider value={{ user, error, signin, signout }}>
                        {user ? <AppRouter /> : <Login />}
                    </SessionContext.Provider>
                </PageContext.Provider>
            )}
        </Suspense>
    )
}

export default App
