import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import App from './App'

axios.defaults.baseURL = process.env.NODE_ENV !== 'development' ? 'https://api.steklo24.moscow' : ''
axios.defaults.withCredentials = true

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
